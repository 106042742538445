import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Card,
    Grid,
    Tab,
    Tabs,
    useTheme,
    alpha,
    Paper,
    Stack,
    Divider,
    Chip,
    Badge,
    Dialog,
    DialogContent,
    IconButton
} from '@mui/material';
import {
    Business as BusinessIcon,
    Apartment as ApartmentIcon,
    Group as GroupIcon,
    MedicalServices as MedicalServicesIcon,
    AccessTime as AccessTimeIcon,
    PlayArrow as PlayArrowIcon,
    OndemandVideo as OndemandVideoIcon,
    FolderOpen as FolderOpenIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import ReactPlayer from 'react-player';
import { storage } from '../../config/firebase';
import { ref, listAll, getMetadata, getDownloadURL } from 'firebase/storage';

const userTypes = [
    {
        label: 'Care Home Agencies',
        key: 'agency',
        icon: BusinessIcon,
        folder: 'agency',
        color: '#2196f3',
        emptyMessage: 'Agency training videos coming soon!'
    },
    {
        label: 'Care Homes',
        icon: ApartmentIcon,
        folder: 'home',
        color: '#4caf50',
        emptyMessage: 'Care home training content will be available shortly!'
    },
    {
        label: 'Care Staff',
        icon: GroupIcon,
        folder: 'carer',
        color: '#ff9800',
        emptyMessage: 'Staff training materials are being prepared!'
    },
    {
        label: 'Nurses',
        icon: MedicalServicesIcon,
        folder: 'nurse',
        color: '#f44336',
        emptyMessage: 'Nursing tutorials will be uploaded soon!'
    }
];

const VideoCard = ({ video, color, sectionTitle, onPlay }) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const formatVideoName = (name) => {
        return name
            .replace(/\.[^/.]+$/, '')
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <Paper
            elevation={0}
            onClick={() => onPlay(video)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                p: 2,
                cursor: 'pointer',
                border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                borderRadius: 2,
                transition: 'all 0.2s ease-in-out',
                transform: isHovered ? 'translateY(-4px)' : 'none',
                '&:hover': {
                    borderColor: alpha(color, 0.3),
                    boxShadow: theme.shadows[4]
                }
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <Box
                    sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '12px',
                        bgcolor: alpha(color, 0.1),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'all 0.2s ease-in-out',
                        ...(isHovered && {
                            bgcolor: color,
                        })
                    }}
                >
                    <PlayArrowIcon
                        sx={{
                            fontSize: 24,
                            color: isHovered ? 'white' : color
                        }}
                    />
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: 600,
                            mb: 0.5
                        }}
                    >
                        {formatVideoName(video.name)}
                    </Typography>

                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ height: 12, my: 'auto' }}
                            />
                        }
                    >
                        <Chip
                            label={sectionTitle}
                            size="small"
                            sx={{
                                bgcolor: alpha(color, 0.1),
                                color: color,
                                fontWeight: 500
                            }}
                        />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AccessTimeIcon
                                sx={{
                                    fontSize: 14,
                                    color: 'text.secondary'
                                }}
                            />
                            <Typography
                                variant="caption"
                                color="text.secondary"
                            >
                                {video.duration || '0:00'}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Paper>
    );
};

const VideoPlayer = ({ video, open, onClose }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadVideo = async () => {
            if (open && video) {
                setIsLoading(true);
                try {
                    const videoRef = ref(storage, video.path);
                    const url = await getDownloadURL(videoRef);
                    setVideoUrl(url);
                } catch (error) {
                    console.error('Error loading video:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadVideo();
        return () => setVideoUrl(''); // Cleanup on unmount
    }, [video, open]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={onClose}
        >
            <DialogContent sx={{ p: 0, bgcolor: 'black', aspectRatio: '16/9' }}>
                {isLoading ? (
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white'
                        }}
                    >
                        <Typography variant="h6">Loading video...</Typography>
                    </Box>
                ) : (
                    <ReactPlayer
                        url={videoUrl}
                        width="100%"
                        height="100%"
                        controls
                        playing
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload'
                                }
                            }
                        }}
                    />
                )}
            </DialogContent>
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'white'
                }}
            >
                <CloseIcon />
            </IconButton>
        </Dialog>
    );
};

const EmptyStateCard = ({ message, color }) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            sx={{
                p: 4,
                textAlign: 'center',
                border: `1px dashed ${alpha(color, 0.3)}`,
                borderRadius: 2,
                bgcolor: alpha(color, 0.02)
            }}
        >
            <FolderOpenIcon sx={{ fontSize: 48, color: alpha(color, 0.5), mb: 2 }} />
            <Typography variant="h6" sx={{ color: alpha(color, 0.8), mb: 1 }}>
                Coming Soon
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {message}
            </Typography>
        </Paper>
    );
};

export default function Training() {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(0);
    const [trainingData, setTrainingData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isPlayerOpen, setIsPlayerOpen] = useState(false);

    useEffect(() => {
        const fetchMetadata = async () => {
            setLoading(true);
            try {
                const data = {};
                userTypes.forEach(type => {
                    data[type.folder] = [];
                });

                for (const userType of userTypes) {
                    try {
                        const folderRef = ref(storage, `trainings/${userType.folder}`);
                        const result = await listAll(folderRef);

                        for (const prefix of result.prefixes) {
                            const sectionName = prefix.name;
                            const sectionVideos = [];

                            const sectionRef = ref(storage, `trainings/${userType.folder}/${sectionName}`);
                            const sectionItems = await listAll(sectionRef);

                            for (const item of sectionItems.items) {
                                try {
                                    const metadata = await getMetadata(item);
                                    sectionVideos.push({
                                        name: item.name,
                                        path: item.fullPath,
                                        duration: metadata.customMetadata?.duration || '0:00'
                                    });
                                } catch (err) {
                                    console.warn(`Error fetching metadata for ${item.name}:`, err);
                                }
                            }

                            if (sectionVideos.length > 0) {
                                data[userType.folder].push({
                                    title: sectionName.replace(/-/g, ' '),
                                    videos: sectionVideos
                                });
                            }
                        }
                    } catch (err) {
                        console.warn(`Error fetching section for ${userType.folder}:`, err);
                    }
                }

                setTrainingData(data);
            } catch (err) {
                console.error('Error fetching training metadata:', err);
                setError('Failed to load training content. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchMetadata();
    }, []);

    const renderTrainingSection = (userType) => {
        const sections = trainingData[userType] || [];
        const currentUserType = userTypes.find(type => type.folder === userType);

        if (loading) {
            return (
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Typography color="text.secondary">Loading training content...</Typography>
                </Box>
            );
        }

        if (sections.length === 0 && currentUserType) {
            return (
                <Box sx={{ maxWidth: 600, mx: 'auto' }}>
                    <EmptyStateCard
                        message={currentUserType.emptyMessage}
                        color={currentUserType.color}
                    />
                </Box>
            );
        }

        return (
            <Stack spacing={4}>
                {sections.map((section, sectionIndex) => (
                    <Box key={sectionIndex}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ mb: 3 }}
                        >
                            <FolderOpenIcon
                                sx={{
                                    color: currentUserType.color,
                                    fontSize: 24
                                }}
                            />
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 600 }}
                            >
                                {section.title}
                            </Typography>
                        </Stack>

                        <Grid container spacing={2}>
                            {section.videos.map((video, videoIndex) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    key={videoIndex}
                                >
                                    <VideoCard
                                        video={video}
                                        color={currentUserType.color}
                                        sectionTitle={section.title}
                                        onPlay={(video) => {
                                            setSelectedVideo(video);
                                            setIsPlayerOpen(true);
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Stack>
        );
    };

    return (
        <Box
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                pt: { xs: 8, md: 12 },
                pb: { xs: 8, md: 12 }
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: { xs: '2.5rem', md: '3.5rem' },
                            fontWeight: 700,
                            mb: 2
                        }}
                    >
                        Training Center
                    </Typography>
                    <Typography
                        variant="h5"
                        color="text.secondary"
                        sx={{
                            maxWidth: '800px',
                            mx: 'auto',
                            lineHeight: 1.6
                        }}
                    >
                        Access our comprehensive training materials designed for each role
                    </Typography>
                </Box>

                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    centered
                    sx={{
                        mb: { xs: 4, md: 6 },
                        '& .MuiTab-root': {
                            minWidth: 'auto',
                            px: 3,
                            py: 2,
                            borderRadius: 2,
                            '&.Mui-selected': {
                                bgcolor: alpha(theme.palette.primary.main, 0.1),
                            }
                        }
                    }}
                >
                    {userTypes.map((type, index) => (
                        <Tab
                            key={index}
                            icon={<type.icon sx={{ mr: 1 }} />}
                            label={type.label}
                            iconPosition="start"
                        />
                    ))}
                </Tabs>

                {error && (
                    <Paper
                        elevation={0}
                        sx={{
                            p: 3,
                            mb: 4,
                            textAlign: 'center',
                            borderRadius: 2,
                            bgcolor: alpha(theme.palette.error.main, 0.05),
                            border: `1px solid ${alpha(theme.palette.error.main, 0.1)}`
                        }}
                    >
                        <Typography color="error" variant="subtitle1">
                            {error}
                        </Typography>
                    </Paper>
                )}

                {userTypes.map((type, index) => (
                    <Box
                        key={index}
                        role="tabpanel"
                        hidden={activeTab !== index}
                        sx={{
                            animation: activeTab === index ?
                                'fadeIn 0.5s ease-in-out' : 'none',
                            '@keyframes fadeIn': {
                                '0%': {
                                    opacity: 0,
                                    transform: 'translateY(10px)'
                                },
                                '100%': {
                                    opacity: 1,
                                    transform: 'translateY(0)'
                                }
                            }
                        }}
                    >
                        {activeTab === index && renderTrainingSection(type.folder)}
                    </Box>
                ))}
            </Container>

            <VideoPlayer
                video={selectedVideo}
                open={isPlayerOpen}
                onClose={() => {
                    setIsPlayerOpen(false);
                    setSelectedVideo(null);
                }}
            />
        </Box>
    );
}

import * as React from "react";
import { Grid, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icon } from "@iconify/react";

// Import the images
import macImage from "src/assets/images/landing.jpeg";

export default function Hero() {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        width: '100%',
        backgroundColor: 'white',
        paddingTop: { xs: '120px', sm: '130px', md: '140px' },
        paddingBottom: { xs: '60px', sm: '80px', md: '100px' },
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <Container>
        <Grid container spacing={8} alignItems="center">
          {/* Content Section */}
          <Grid item xs={12} md={6}>
            {/* Title and Description Stack */}
            <Stack spacing={4} sx={{ maxWidth: '100%' }}>
              <Stack spacing={3}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
                    fontWeight: 700,
                    lineHeight: 1.2,
                    letterSpacing: '-0.02em',
                    color: 'text.primary'
                    
                  }}
                >
                  Elevate Your Care
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.25rem' },
                    lineHeight: 1.6,
                    color: 'text.secondary',
                    fontWeight: 400,
                    maxWidth: '540px'
                  }}
                >
                  Transform the way you manage your care services with our
                  comprehensive solution. Streamline operations, manage shifts,
                  generate digital timesheets, and ensure top-notch care.
                </Typography>
              </Stack>

              {/* Mobile Apps Section */}
              <Box sx={{ pt: { xs: 2, md: 4 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 3,
                    color: 'text.primary',
                    fontWeight: 500,
                    fontSize: { xs: '1rem', sm: '1.1rem' }
                  }}
                >
                  Available on Android and iOS
                </Typography>

                <Stack
                  direction="row"
                  spacing={3}
                >
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={
                      <Icon
                        icon="logos:google-play-icon"
                        width={20}
                        height={24}
                      />
                    }
                    sx={{
                      py: 1.5,
                      px: { xs: 3, sm: 4 },
                      fontSize: '1rem',
                      textTransform: 'none',
                      borderRadius: 2,
                      backgroundColor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                        boxShadow: theme.shadows[8]
                      }
                    }}
                  >
                    Android
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={
                      <Icon
                        icon="logos:apple-app-store"
                        width={24}
                        height={24}
                      />
                    }
                    sx={{
                      py: 1.5,
                      px: { xs: 3, sm: 4 },
                      fontSize: '1rem',
                      textTransform: 'none',
                      borderRadius: 2,
                      borderWidth: 2,
                      '&:hover': {
                        borderWidth: 2,
                        backgroundColor: 'background.paper'
                      }
                    }}
                  >
                    iOS
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                padding: '20px',
                borderRadius: 4,
                overflow: 'hidden'
              }}
            >
              {/* Background Decoration */}
              <Box
                sx={{
                  position: 'absolute',
                  top: -100,
                  right: -100,
                  width: 400,
                  height: 400,
                  borderRadius: '50%',
                  zIndex: 0
                }}
              />

              {/* Image */}
              <Box
                component="img"
                src={macImage}
                alt="Care Management Platform"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 3,
                  boxShadow: theme.shadows[4],
                  position: 'relative',
                  zIndex: 1,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: theme.shadows[8]
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
import { Box, Container, Typography, styled } from "@mui/material";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: "block", md: "flex" }}
        alignItems="center"
        textAlign={{ xs: "center", md: "left" }}
        justifyContent="center"
      >
        <Box sx={{ mt: 6, textAlign: "center", color: "text.secondary" }}>
          <Typography variant="body2">
            © 2024 Wyecare. All rights reserved.
          </Typography>
          <Typography variant="body2">Contact us: admin@wyecaresolutions.com</Typography>
        </Box>
        {/* <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Crafted by{' '}
          <Link
            href="https://bloomui.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            BloomUI.com
          </Link>
        </Typography> */}
      </Box>
    </FooterWrapper>
  );
}

export default Footer;

import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  ThemeProvider,
  useTheme,
} from "@mui/material";

const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, marginTop: 4, marginBottom: 4 }}>
          <Typography variant="h3" color="primary" gutterBottom>
            Privacy Policy
          </Typography>

          <Typography variant="h4" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            Welcome to WyeCare's Healthcare Staffing and Resident Management
            Application. This Privacy Policy outlines our commitment to
            protecting your personal information and details our data handling
            practices in compliance with the General Data Protection Regulation and other applicable data protection laws.
          </Typography>

          <Typography variant="h4" gutterBottom>
            2. Data Collection and Processing
          </Typography>
          <Typography paragraph>
            Our application collects and processes the following categories of
            information:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Device Identifiers"
                secondary="We collect device identifiers solely for the purpose of sending push notifications to specific devices. This enables us to deliver important updates and alerts to our users effectively."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Healthcare Professionals and Agency Staff Data"
                secondary="Including but not limited to: full name, contact details, professional qualifications, employment history, availability schedules, and performance records."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Resident Information"
                secondary="Including demographic data, medical histories, care requirements, and ongoing health status updates."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Care Home and Agency Information"
                secondary="Including organizational details, staff rosters, and operational data."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Time and Attendance Data"
                secondary="Including clock-in/out times, shift details, and QR code scan data for timesheet management."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Communication Records"
                secondary="Including in-app messages, notifications, and support inquiries."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            3. Purpose of Data Processing
          </Typography>
          <Typography paragraph>
            We process the collected information for the following purposes:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Push Notifications"
                secondary="Device identifiers are used exclusively for delivering push notifications to ensure users receive important updates and alerts."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Staffing Management"
                secondary="Facilitating efficient staff allocation and shift scheduling."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Resident Care Management"
                secondary="Enabling comprehensive resident care planning, monitoring, and reporting."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Time and Attendance Tracking"
                secondary="Accurate recording and verification of working hours for payroll and compliance purposes."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Quality Assurance"
                secondary="Monitoring and improving the quality of care provided and operational efficiency."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Compliance and Reporting"
                secondary="Meeting regulatory requirements and generating necessary reports for internal and external stakeholders."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            4. Legal Basis for Processing
          </Typography>
          <Typography paragraph>
            We process personal data on the following legal grounds:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Contractual Necessity"
                secondary="Processing necessary for the performance of contracts with care homes, agencies, and staff."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Legitimate Interests"
                secondary="Processing necessary for the legitimate interests of efficient healthcare staffing and resident care management, including the use of device identifiers for push notifications."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Consent"
                secondary="Where required, we obtain explicit consent for specific data processing activities, including push notifications."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            5. Data Sharing and Disclosure
          </Typography>
          <Typography paragraph>
            We maintain a strict policy against the sale of personal
            information. Data sharing is limited to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Push Notification Services"
                secondary="Device identifiers are shared with our push notification service provider solely for the purpose of delivering notifications."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Care Homes and Staffing Agencies"
                secondary="Sharing operationally relevant data with authorized partners."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Service Providers"
                secondary="Engaging trusted third-parties for essential services such as cloud hosting and push notification delivery."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Legal and Regulatory Bodies"
                secondary="Disclosing information as required by law or in response to valid legal requests."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            6. Data Retention
          </Typography>
          <Typography paragraph>
            We retain personal data for as long as necessary to fulfill the
            purposes outlined in this policy, unless a longer retention period
            is required or permitted by law. Device identifiers are retained only for as long as necessary to provide push notification services.
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="The duration of the user's active engagement with our services" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Legal and regulatory requirements in the healthcare sector" />
            </ListItem>
            <ListItem>
              <ListItemText primary="The potential for disputes or legal claims related to the data" />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            7. Data Security Measures
          </Typography>
          <Typography paragraph>
            We implement robust security measures to protect your data,
            including:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Encryption"
                secondary="Using industry-standard encryption for data in transit and at rest."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Access Controls"
                secondary="Implementing strict access controls and authentication mechanisms."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Regular Security Audits"
                secondary="Conducting periodic security assessments and vulnerability testing."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Employee Training"
                secondary="Providing regular data protection and security training to our staff."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            8. Your Data Protection Rights
          </Typography>
          <Typography paragraph>
            Under the GDPR and other applicable data protection laws, you have
            the following rights:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Right to Access"
                secondary="You can request a copy of the personal data we hold about you."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Right to Rectification"
                secondary="You can request correction of any inaccurate or incomplete data."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Right to Erasure"
                secondary="In certain circumstances, you can request the deletion of your personal data."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Right to Restrict Processing"
                secondary="You can request the restriction of processing of your personal data in specific scenarios."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Right to Data Portability"
                secondary="You can request a copy of your data in a structured, commonly used, and machine-readable format."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Right to Object"
                secondary="You can object to the processing of your personal data in certain circumstances."
              />
            </ListItem>
          </List>

          <Typography variant="h4" gutterBottom>
            9. Push Notification Preferences
          </Typography>
          <Typography paragraph>
            You can control push notification settings through your device settings. You may opt-out of push notifications at any time, though this may impact the app's ability to deliver important updates and alerts.
          </Typography>

          <Typography variant="h4" gutterBottom>
            10. Changes to This Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or for legal, operational, or regulatory reasons. We
            will notify users of any material changes through our application or
            via email.
          </Typography>

          <Typography variant="h4" gutterBottom>
            11. Contact Information
          </Typography>
          <Typography paragraph>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our data practices, please contact our Data
            Protection Officer:
          </Typography>
          <Box sx={{ marginLeft: 2 }}>
            <Typography>Wyecare Solutions</Typography>
            <Typography>Kocheri Puthuparambu</Typography>
            <Typography>Changanacherry</Typography>
            <Typography>686105</Typography>
            <Typography>India</Typography>
            <Typography>Email: admin@wyecaresolutions.com</Typography>
          </Box>

          <Typography sx={{ marginTop: 4, fontStyle: "italic" }}>
            Effective Date: 6th October 2024
          </Typography>
          <Typography sx={{ fontStyle: "italic" }}>
            Last Updated: 6th October 2024
          </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
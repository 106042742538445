import * as React from "react";
import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import AppAppBar from "./components/AppBar";
import Hero from "./components/Hero";
import Testimonials from "./components/Testimonials";
import Highlights from "./components/Highlights";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import FAQ from "./components/FAQ";
import Training from "./components/Trainings";
import AboutUs from "./components/AboutUs";
import { red } from "@mui/material/colors";

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");


  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const theme = useTheme();
  React.useEffect(() => {
    const handleScroll = (e: Event) => {
      e.preventDefault();
      const targetId = (e.target as HTMLAnchorElement).getAttribute('href')?.substring(1);
      if (targetId) {
        const element = document.getElementById(targetId);
        if (element) {
          const offset = 128; // Adjust this value based on your header height
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    };

    // Add scroll event listeners to all navigation links
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', handleScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleScroll);
      });
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        backgroundColor: 'white',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

        <Box component="main">
          <Hero />

          <Box sx={{
            maxWidth: '100%',
            px: { xs: 2, sm: 4, md: 6 },
            overflow: 'hidden',
          }}>
            <Divider />

            <Highlights />
            <Pricing />
            <Divider />

            <Training />
            <Divider />

            <Testimonials />

            <Divider />

            {/* <AboutUs />
            <Divider /> */}

            {/* <FAQ />
            <Divider /> */}

            <Footer />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import ThemeProvider from "./theme/ThemeProvider";
import LandingPage from "./landing/LandingPage";
import PrivacyPolicy from "./privacy-policy"; // Import the PrivacyPolicy component we created earlier
import AccountDeletionGuide from "./deletion-policy";
import Training from "./landing/components/Trainings";
import WyecarePricing from "./landing/components/Pricing";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* training route */}
          <Route path="/trainings" element={<Training />} />
          <Route path="/pricing" element={<WyecarePricing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/account-deletion-guide"
            element={<AccountDeletionGuide />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

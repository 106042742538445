import * as React from "react";
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  alpha
} from "@mui/material";
import {
  Star as StarIcon,
  AddComment as AddCommentIcon,
  RateReview as RateReviewIcon
} from '@mui/icons-material';

const EmptyTestimonialCard = () => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
        background: theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.main, 0.02)
          : alpha(theme.palette.primary.main, 0.1),
        border: `1px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          background: theme.palette.mode === 'light'
            ? alpha(theme.palette.primary.main, 0.05)
            : alpha(theme.palette.primary.main, 0.15),
          transform: 'translateY(-4px)'
        }
      }}
    >
      <Box
        sx={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          background: alpha(theme.palette.primary.main, 0.1)
        }}
      >
        <RateReviewIcon
          color="primary"
          sx={{ fontSize: 30 }}
        />
      </Box>
      <Typography
        variant="body1"
        color="text.secondary"
        align="center"
        sx={{ mb: 2 }}
      >
        Share your experience
      </Typography>
      <Button
        variant="outlined"
        startIcon={<AddCommentIcon />}
        size="small"
        disabled
      >
        Add Testimonial ( Coming Soon )
      </Button>
    </Card>
  );
};

const PlaceholderStars = () => (
  <Box sx={{ display: 'flex', gap: 0.5, mb: 2 }}>
    {[...Array(5)].map((_, index) => (
      <StarIcon
        key={index}
        sx={{
          fontSize: 20,
          color: (theme) => alpha(theme.palette.primary.main, 0.2)
        }}
      />
    ))}
  </Box>
);

const TestimonialPlaceholder = () => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '100%',
        p: 3,
        background: theme.palette.mode === 'light'
          ? alpha(theme.palette.primary.main, 0.01)
          : alpha(theme.palette.primary.main, 0.05),
        border: `1px dashed ${alpha(theme.palette.primary.main, 0.1)}`,
      }}
    >
      <PlaceholderStars />
      <Box
        sx={{
          height: 12,
          mb: 1,
          borderRadius: 1,
          background: (theme) => alpha(theme.palette.primary.main, 0.1),
          width: '80%'
        }}
      />
      <Box
        sx={{
          height: 12,
          mb: 1,
          borderRadius: 1,
          background: (theme) => alpha(theme.palette.primary.main, 0.1),
          width: '90%'
        }}
      />
      <Box
        sx={{
          height: 12,
          mb: 3,
          borderRadius: 1,
          background: (theme) => alpha(theme.palette.primary.main, 0.1),
          width: '70%'
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            background: (theme) => alpha(theme.palette.primary.main, 0.1)
          }}
        />
        <Box>
          <Box
            sx={{
              height: 12,
              mb: 1,
              borderRadius: 1,
              background: (theme) => alpha(theme.palette.primary.main, 0.1),
              width: 100
            }}
          />
          <Box
            sx={{
              height: 10,
              borderRadius: 1,
              background: (theme) => alpha(theme.palette.primary.main, 0.1),
              width: 80
            }}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 8, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      {/* Background decoration */}
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '35%',
          width: '30%',
          height: '60%',
          backgroundColor: 'white',
          zIndex: -1
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: { xs: 6, sm: 8 },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          sx={{
            mb: 2,
            fontSize: { xs: '2.5rem', sm: '3.5rem' },
            fontWeight: 700,
            textAlign: 'center'
          }}
        >
          Testimonials
        </Typography>
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{
            mb: 4,
            textAlign: 'center',
            maxWidth: 'sm'
          }}
        >
          We're just getting started! Be among the first to share your experience with our platform.
        </Typography>

        <Button
          variant="contained"
          size="large"
          startIcon={<AddCommentIcon />}
          disabled
          sx={{
            py: 1.5,
            px: 3,
            mb: 6,
            fontSize: '1.1rem'
          }}
        >
          Share Your Story ( Coming Soon )
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <EmptyTestimonialCard />
        </Grid>
        {[...Array(5)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TestimonialPlaceholder />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Want to see what others are saying?
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Check back soon as our community grows and shares their experiences!
        </Typography>
      </Box>
    </Container>
  );
}
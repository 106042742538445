import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Chip,
  Stack,
  useTheme,
  Divider,
  Tooltip,
  IconButton,
  Paper,
  Tab,
  Tabs
} from "@mui/material";
import {
  Check as CheckIcon,
  Info as InfoIcon
} from "@mui/icons-material";

// Simplified currency mapping (all in USD)
const currencyMap = {
  'en-US': { currency: 'USD', symbol: '$', rate: 1 },
  'en-GB': { currency: 'USD', symbol: '$', rate: 1 },
  'de-DE': { currency: 'USD', symbol: '$', rate: 1 },
  'fr-FR': { currency: 'USD', symbol: '$', rate: 1 },
  'es-ES': { currency: 'USD', symbol: '$', rate: 1 },
  'it-IT': { currency: 'USD', symbol: '$', rate: 1 },
  'en-AU': { currency: 'USD', symbol: '$', rate: 1 },
  'en-CA': { currency: 'USD', symbol: '$', rate: 1 },
  'ja-JP': { currency: 'USD', symbol: '$', rate: 1 },
  'en-IN': { currency: 'USD', symbol: '$', rate: 1 },
  'hi-IN': { currency: 'USD', symbol: '$', rate: 1 },
  default: { currency: 'USD', symbol: '$', rate: 1 }
};

const basePricingPlans = [
  {
    title: "Agency Basic",
    price: 75,
    period: "/month",
    bestFor: "Small to medium agencies",
    isPopular: false,
    staffLimit: 25,
    shiftLimit: 350,
    homeLimit: 10,
    category: 'agency',
    features: [
      "Up to 25 staff members",
      "Link up to 10 care homes",
      "Up to 100 staff members",
      "Link up to 8 care homes",
      "Staff scheduling",
      "Basic reporting",
      "Up to 300 shifts per month",
      "Up to 500 shifts per month",
      "Home management",
      "Shift distribution",
      "Mobile app access",
      "Email support"
    ],
    extraCharges: [
      {
        title: "Additional Staff",
        description: "$4 per additional staff member/month",
        tooltip: "Charged monthly for each staff member over 25"
      },
      {
        title: "Additional Home",
        description: "$25 per additional home/month",
        tooltip: "Charged monthly for each home over 10"
      },
      {
        title: "Additional Shifts",
        description: "$0.25 per additional shift",
        tooltip: "Charged for each shift over 350 per month"
      }
    ]
  },
  {
    title: "Agency Professional",
    price: 80,
    period: "/month",
    bestFor: "Large agencies",
    isPopular: true,
    staffLimit: 50,
    homeLimit: 15,
    shiftLimit: 800,
    category: 'agency',
    features: [
      "Up to 250 staff members",
      "Link up to 50 care homes",
      "All Basic features",
      "Advanced reporting",
      "Priority support",
      "Training sessions",
      "Custom branding",
      "Up to 1600 shifts per month",
      "API access"
    ],
    extraCharges: [
      {
        title: "Additional Staff",
        description: "$3.20 per additional staff member/month",
        tooltip: "Charged monthly for each staff member over 50"
      },
      {
        title: "Additional Home",
        description: "$19 per additional home/month",
        tooltip: "Charged monthly for each home over 15"
      },
      {
        title: "Additional Shifts",
        description: "$0.20 per additional shift",
        tooltip: "Charged for each shift over 800 per month"
      }
    ]
  },
  {
    title: "Care Home Basic",
    price: 62,
    period: "/month",
    bestFor: "Small care homes",
    isPopular: false,
    staffLimit: 25,
    shiftLimit: 300,
    category: 'care-home',
    features: [
      "Up to 80 staff members",
      "Staff scheduling",
      "Basic reporting",
      "Mobile app access",
      "Up to 400 shifts per month",
      "Email support"
    ],
    extraCharges: [
      {
        title: "Additional Staff",
        description: "$4 per additional staff member/month",
        tooltip: "Charged monthly for each staff member over 25"
      },
      {
        title: "Additional Shifts",
        description: "$0.30 per additional shift",
        tooltip: "Charged for each shift over 300 per month"
      },
      {
        title: "Resident Management Module",
        description: "$30/month includes up to 50 residents",
        tooltip: "Optional module for managing resident care and records"
      },
      {
        title: "Additional Residents",
        description: "$5 per additional resident/month",
        tooltip: "When using Resident Management, charged for each resident over 50"
      }
    ]
  },
  {
    title: "Care Home Professional",
    price: 80,
    period: "/month",
    bestFor: "Medium to large care homes",
    isPopular: false,
    staffLimit: 50,
    shiftLimit: 800,
    category: 'care-home',
    features: [
      "Up to 250 staff members",
      "Staff scheduling",
      "Advanced reporting",
      "Mobile app access",
      "Up to 1500 shifts per month",
      "Email support"
    ],
    extraCharges: [
      {
        title: "Additional Staff",
        description: "$4 per additional staff member/month",
        tooltip: "Charged monthly for each staff member over 50"
      },
      {
        title: "Additional Shifts",
        description: "$0.25 per additional shift",
        tooltip: "Charged for each shift over 800 per month"
      },
      {
        title: "Enhanced Resident Management Module",
        description: "$30/month includes up to 70 residents",
        tooltip: "Optional enhanced module with advanced resident care features"
      },
      {
        title: "Additional Residents",
        description: "$5 per additional resident/month",
        tooltip: "When using Resident Management, charged for each resident over 70"
      }
    ]
  }
];

const ModernPricingCard = ({ plan, theme }) => {
  return (
    <Card
      elevation={plan.isPopular ? 8 : 1}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        backgroundColor: '#C6E7FF',
        borderRadius: '25px',
        overflow: 'visible',
        '&:hover': {
          transform: plan.isPopular ? 'scale(1.03)' : 'scale(1.01)',
          boxShadow: theme.shadows[plan.isPopular ? 12 : 4],
        }
      }}
    >
      {plan.isPopular && (
        <Chip
          label="Most Popular"
          color="primary"
          sx={{
            position: 'absolute',
            top: -16,
            right: 24,
            px: 2,
            py: 0.5
          }}
        />
      )}

      <CardContent sx={{ p: 3, flexGrow: 1 }}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {plan.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {plan.bestFor}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontWeight: 700,
                display: 'inline-flex',
                alignItems: 'baseline',
                color: plan.isPopular ? 'primary.main' : 'text.primary',
              }}
            >
              ${plan.price}
              <Typography
                variant="subtitle2"
                component="span"
                color="text.secondary"
                sx={{ ml: 1 }}
              >
                {plan.period}
              </Typography>
            </Typography>
          </Box>

          <Divider />

          <Stack spacing={1.5}>
            {plan.features.map((feature, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={1.5}
                alignItems="center"
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'text.primary' }
                }}
              >
                <CheckIcon sx={{ color: 'success.main', fontSize: 18 }} />
                <Typography variant="body2">{feature}</Typography>
              </Stack>
            ))}
          </Stack>

          {plan.extraCharges.length > 0 && (
            <>
              <Divider />
              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mb: 1.5 }}
                >
                  Additional Charges
                </Typography>
                <Stack spacing={1}>
                  {plan.extraCharges.map((charge, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {charge.description}
                      </Typography>
                      {charge.tooltip && (
                        <Tooltip title={charge.tooltip} arrow>
                          <IconButton size="small" sx={{ p: 0 }}>
                            <InfoIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const WyecarePricing = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('agency');

  const filteredPlans = basePricingPlans.filter(plan => plan.category === activeTab);

  return (
    <Container maxWidth="lg" sx={{ py: 6, bgcolor: 'background.paper' }} id="pricing">
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography
          variant="h1"
          sx={{
            mb: 2,
          }}
        >
          Simple Transparent Pricing
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{
            maxWidth: '600px',
            mx: 'auto',
            fontWeight: 800
          }}
        >
          Choose the perfect plan for your care facility
        </Typography>
      </Box>

      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 6,
          backgroundColor: 'transparent'
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          sx={{
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '15px'
            }
          }}
        >
          <Tab
            label="Agency Plans"
            value="agency"
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1.1rem'
            }}
          />
          <Tab
            label="Care Home Plans"
            value="care-home"
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '1.1rem'
            }}
          />
        </Tabs>
      </Paper>

      <Grid container spacing={3} alignItems="stretch">
        {filteredPlans.map((plan) => (
          <Grid item xs={12} md={6} key={plan.title}>
            <ModernPricingCard
              plan={plan}
              theme={theme}
            />
          </Grid>
        ))}
      </Grid>


    </Container>
  );
};

export default WyecarePricing;
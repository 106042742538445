import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme, alpha } from "@mui/material";
import ElderlyIcon from "@mui/icons-material/Elderly";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WorkIcon from "@mui/icons-material/Work";
import TimelineIcon from "@mui/icons-material/Timeline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ChatIcon from "@mui/icons-material/Chat";
import { DescriptionOutlined } from "@mui/icons-material";

const items = [
  {
    icon: <DescriptionOutlined />,
    title: "Digital Documentation",
    description: "Paperless timesheets and invoicing for streamlined operations and reduced administrative burden.",
    category: "Documentation"
  },
  {
    icon: <ElderlyIcon />,
    title: "Resident Management",
    description: "Easily manage your residents, keep track of their activities and ensure personalized care.",
    category: "Care"
  },
  {
    icon: <LocalPharmacyIcon />,
    title: "Medication Tracking",
    description: "Monitor resident medications and ensure timely administration for their well-being.",
    category: "Health"
  },
  {
    icon: <NotificationsActiveIcon />,
    title: "Priority Alerts",
    description: "Receive real-time alerts for resident priorities and urgent needs.",
    category: "Alerts"
  },
  {
    icon: <WorkIcon />,
    title: "Shift Registration",
    description: "Record and manage shifts effortlessly, providing a complete work history for carers.",
    category: "Work"
  },
  {
    icon: <ManageAccountsIcon />,
    title: "Agency Management",
    description: "Efficiently manage shifts, carers, and timesheets with robust fraud prevention features.",
    category: "Management"
  },
  {
    icon: <ChatIcon />,
    title: "Instant Communication (Coming Soon)",
    description: "Real-time chat and messaging system for seamless communication between staff, management, and agencies.",
    category: "Communication"
  },

];

const FeatureCard = ({ item }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '100%',
        p: 3,
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'visible',
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: theme.shadows[4],
          '& .feature-icon': {
            transform: 'scale(1.1)',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }
        }
      }}
    >
      <Box
        className="feature-icon"
        sx={{
          width: 56,
          height: 56,
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          transition: 'all 0.3s ease-in-out',
          mb: 2
        }}
      >
        {React.cloneElement(item.icon, { sx: { fontSize: 28 } })}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          px: 1,
          py: 0.5,
          borderRadius: '4px',
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 500
          }}
        >
          {item.category}
        </Typography>
      </Box>

      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: '1.25rem',
          fontWeight: 600,
          mb: 1
        }}
      >
        {item.title}
      </Typography>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          lineHeight: 1.6
        }}
      >
        {item.description}
      </Typography>
    </Card>
  );
};

export default function Highlights() {
  const theme = useTheme();

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 8, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        bgcolor: 'white',
      }}
    >
      {/* Background decorations */}
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '35%',
          height: '40%',
          background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.05)} 0%, transparent 70%)`,
          zIndex: 0
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '5%',
          width: '35%',
          height: '40%',
          background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.05)} 0%, transparent 70%)`,
          zIndex: 0
        }}
      />

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ textAlign: 'center', mb: { xs: 6, sm: 8 } }}>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              mb: 2,
              fontSize: { xs: '2.5rem', sm: '3.5rem' },
              fontWeight: 700
            }}
          >
            Key Features
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.6
            }}
          >
            Discover the powerful features that make our platform the perfect solution
            for care homes, carers, and agencies.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                animation: 'fadeIn 0.5s ease-out',
                animationDelay: `${index * 0.1}s`,
                '@keyframes fadeIn': {
                  from: {
                    opacity: 0,
                    transform: 'translateY(20px)'
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateY(0)'
                  }
                }
              }}
            >
              <FeatureCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./firebaseConfig";
import { getStorage } from "firebase/storage";

console.log(firebaseConfig);

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);
